import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IAlert } from '@/models/IAlert'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IDeviceTraitedData } from '@/models/IDeviceTraitedData'
import type { IAlertEvent } from '@/models/IAlertEvent'
import type { IMetric } from '@/models/IMetric'
import type { IAssetChartsConfiguration } from '@/models/IAssetChartsConfiguration'
import type { IDevice } from '@/models/IDevice'
import type { IAssetMetricsConfiguration } from '@/models/IAssetMetricsConfiguration'

const resource = 'api/v1/device'

export default {
    getDeviceDtdByIdDevice(id: number, filter_date): Promise<AxiosResponse<IServerResponse<IDeviceTraitedData[]>>> {
        return axios.get(`${resource}/${id}/device_traited_data${filter_date}`)
    },

    getHistoricalByDevice(device_number: string, params): Promise<AxiosResponse<IServerResponse<{data: IDeviceTraitedData[]; device: IDevice}>>> {
        return axios.get(`${resource}/${device_number}/historical_traited_data?${params}`)
    },

    getCoordinatesByDevice(id_device: string, params): Promise<AxiosResponse<IServerResponse<{data: Array<any>}>>> {
        return axios.get(`${resource}/${id_device}/historical_coordinates?${params}`)
    },

    getDeviceDataConsumptionByIdDevice(id: number, data): Promise<AxiosResponse<IServerResponse<string[]>>> {
        return axios.post(`${resource}/${id}/device_traited_data/consumption`, data)
    },

    getDeviceInstallationValidationWithUser(deviceNumber: string): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`${resource}/${deviceNumber}/is_installable/user`)
    },

    getAllAlertByIdDevice(id: number): Promise<AxiosResponse<IServerResponse<IAlert[]>>> {
        return axios.get(`${resource}/${id}/alerts`)
    },

    getAlertsEventsByIdDevice(id: number): Promise<AxiosResponse<IServerResponse<IAlertEvent[]>>> {
        return axios.get(`${resource}/${id}/alerts/events`)
    },

    getAllMetricByIdDevice(id: number): Promise<AxiosResponse<IServerResponse<IMetric[]>>> {
        return axios.get(`${resource}/${id}/metrics`)
    },

    getDeviceTraitedDataCSVByDeviceNumber(device_number: string): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`${resource}/${device_number}/device_data_csv`, { responseType: 'blob' })
    },

    getAssetChartsConfigurations(device_number: number): Promise<AxiosResponse<IServerResponse<{asset_charts_configurations: Array<IAssetChartsConfiguration>}>>> {
        return axios.get(`${resource}/${device_number}/asset_charts_configurations/v2`)
    },

    getAssetMetricsConfigurations(device_number: number): Promise<AxiosResponse<IServerResponse<{asset_metrics_configurations: Array<IAssetMetricsConfiguration>}>>> {
        return axios.get(`${resource}/${device_number}/asset_metrics_configurations/v2`)
    },
}
